import { useEffect, useState } from 'react'
import { Token, Currency, ChainId } from '@pancakeswap/sdk'
import { Button, Text, ErrorIcon, Flex, Message, Checkbox, Link, Tag, Grid } from '@pancakeswap/uikit'
import { AutoColumn } from 'components/Layout/Column'
import { useAddUserToken } from 'state/user/hooks'
import { getBlockExploreLink, getBlockExploreName } from 'utils'
import truncateHash from '@pancakeswap/utils/truncateHash'
import { useCombinedInactiveList } from 'state/lists/hooks'
import { ListLogo } from 'components/Logo'
import { useTranslation } from '@pancakeswap/localization'
import { chains } from 'utils/wagmi'
import { useActiveChainId } from 'hooks/useActiveChainId'

interface ImportProps {
  tokens: Token[]
  handleCurrencySelect?: (currency: Currency) => void
}

const getStandard = (chainId: ChainId) => 'ERC20'

const IMPORT_COUNTDOWN = 5
function ImportToken({ tokens, handleCurrencySelect }: ImportProps) {
  const { chainId } = useActiveChainId()

  const { t } = useTranslation()

  const [confirmed, setConfirmed] = useState(false)

  const addToken = useAddUserToken()

  // use for showing import source on inactive tokens
  const inactiveTokenList = useCombinedInactiveList()

  const [importCountdown, setImportCountdown] = useState(IMPORT_COUNTDOWN)

  useEffect(() => {
    if (confirmed) {
      const timer = setInterval(() => {
        setImportCountdown((ic) => ic - 1)
      }, 1000)
      return () => clearInterval(timer)
    }
    return undefined
  }, [confirmed])

  return (
    <AutoColumn gap="lg">
      <Message variant="warning">
        <Text style={{ color: '#FDE53A !important' }}>
          {t(
            'Anyone can create a %standard% token on %network% with any name, including creating fake versions of existing tokens and tokens that claim to represent projects that do not have a token.',
            {
              standard: getStandard(chainId),
              network: chains.find((c) => c.id === chainId)?.name,
            },
          )}
          <br />
          <br />
          {t('If you purchase an arbitrary token, you may be unable to sell it back.')}
        </Text>
      </Message>

      {tokens.map((token) => {
        const list = token.chainId && inactiveTokenList?.[token.chainId]?.[token.address]?.list
        const address = token.address ? `${truncateHash(token.address)}` : null
        return (
          <Grid key={token.address} gridTemplateRows="42px 1fr">
            {list !== undefined ? (
              <Tag
                variant="success"
                outline
                scale="sm"
                startIcon={list.logoURI && <ListLogo logoURI={list.logoURI} size="12px" />}
              >
                {t('via')} {list.name}
              </Tag>
            ) : (
              <Tag
                variant="failure"
                outline
                scale="sm"
                style={{ width: 'fit-content' }}
                startIcon={<ErrorIcon color="failure" />}
              >
                {t('Unknown Source')}
              </Tag>
            )}

            <Flex flexDirection="column" gap="4px" width="100%" mt="16px">
              <Text fontSize="14px" fontWeight="500" mb="4px">
                {token.name} ({token.symbol})
              </Text>
              {token.chainId && (
                <>
                  <Text fontSize="14px" fontWeight="500">
                    {address}
                  </Text>
                  <Link target="_blank" href={getBlockExploreLink(token.address, 'address', token.chainId)} external>
                    (
                    {t('View on %site%', {
                      site: getBlockExploreName(token.chainId),
                    })}
                    )
                  </Link>
                </>
              )}
            </Flex>
          </Grid>
        )
      })}

      <Flex justifyContent="space-between" alignItems="center">
        <Flex alignItems="center" onClick={() => setConfirmed(!confirmed)}>
          <Checkbox
            scale="sm"
            name="confirmed"
            type="checkbox"
            checked={confirmed}
            onChange={() => setConfirmed(!confirmed)}
          />
          <Text ml="8px" style={{ userSelect: 'none' }}>
            {t('I understand')}
          </Text>
        </Flex>
        <Button
          variant="primary"
          rounded
          disabled={!confirmed || importCountdown > 0}
          onClick={() => {
            tokens.forEach((token) => addToken(token))
            if (handleCurrencySelect) {
              handleCurrencySelect(tokens[0])
            }
          }}
          className=".token-dismiss-button"
          style={{
            color: !confirmed || importCountdown > 0 ? '#fff' : '#282828',
            borderRadius: 24,
          }}
        >
          {t('Import')}
          {confirmed && importCountdown > 0 ? ` (${importCountdown})` : ''}
        </Button>
      </Flex>
    </AutoColumn>
  )
}

export default ImportToken
